import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ContractDetail from '@/view/payment/payment-contract/details';


export default {
  setup(__props) {


return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(ContractDetail)))
}
}

}